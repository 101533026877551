import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import ContentPart from "../../components/services/template/content"
import BackFlowHero from "../../components/heros/backFlowHero"

const TitleText = `
	Backflows Preventer Inspections
`
const BackflowsPrevInspPage = () => (
	<Layout>
		<Seo title={TitleText} description="Backflow preventer assemblies are installed wherever water flows throughout your building’s infrastructure, and they are critical to a properly functioning fire protection system." />
		<BackFlowHero />
		<ContentWrapper>
			<ContentPart>
				<p>Backflow preventer assemblies are installed wherever water flows throughout your building’s infrastructure, and they are critical to a properly functioning fire protection system.</p>
				<p>They stop your sprinklers from over-using water in non-emergency situations, and they prevent wastewater from entering the local freshwater grid.</p>
				<p>We assess your backflow preventers by performing directional tests on your water flow, checking for adequate water pressure, and noticing any needed repairs to your system. Then, repairing any deficiencies, you require and submit testing reports to the local water department.</p>
				<p>Regulations mandate your annual inspection be completed by a professional.</p>
				<p><Link to="/contact" title="backflow preventer inspections services">Contact us today to test your backflow preventers.</Link></p>
			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default BackflowsPrevInspPage